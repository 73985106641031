@font-face {
  font-family: "Poppins";
  src: url("/fonts/poppins-regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/poppins-bold.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Overpass";
  src: url("/fonts/overpass-var.woff2") format("woff2");
  font-display: swap;
  font-weight: 400 600;
}
